import React, { useCallback, useEffect, useRef, useState } from 'react';
import Moment from 'moment';
import LoadingPanel from '../../components/LoadingPanel';
import { fetchApi } from '../../services/api';
import {
    PropertyViewModel, getPropertyImageType, PropertyImage, getPropertyLotSizeUnit, getPropertyFrontBackProperty,
    PropertyBid, getPropertyTitle, getPropertyEscrow, getPropertyHOA, PropertyStatus, getPropertyStatus, getPropertyRenovation,
    PropertyBidStatus, PropertyOffer, PropertyOfferStatus, PropertyInvestor, PropertyImageType, PropertyInvestorOfferingStatus
} from '../../utils/property';
import AgentAvatar from '../Agent/AgentAvatar';
import Map from '../Map';
import Marker from '../Map/Marker';
import InfoWindow from '../Map/InfoWindow';
import ImageViewer, { imgDetail } from '../ImageViewer';
import { toYesNo, formatCurrency } from '../../utils/generic';
import AgentAvatarListing from '../Agent/AgentAvatarListing';
import { AgentLevel, AgentViewModel } from '../../utils/agent';
import { Window, Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { InputSuffix, TextArea, TextBox } from '@progress/kendo-react-inputs';
import { DatePicker } from '@progress/kendo-react-dateinputs';
import Linkify from 'react-linkify';
import { AuditLogs } from './AuditLogs';
import PropertyProfileAttributes from './PropertyProfileAttributes';
import PropertyProfileSpecifications from './PropertyProfileSpecifications';
import PropertyProfileCommunication from './PropertyProfileCommunication';
import PropertyProfileAddBid from './PropertyProfileAddBid';
import PropertyProfileListing from './PropertyProfileListing';
import PropertyProfileAddOffer from './PropertyProfileAddOffer';
import PropertyProfileOffer from './PropertyProfileOffer';
import PropertyProfileViewings from './PropertyProfileViewings';
import PropertyProfileInvestment from './PropertyProfileInvestment';
import FadeInDivPanel from '../FadeInDivPanel';
import PropertyProfileInvestor from './PropertyProfileInvestor';
import PropertyProfileInvestorProgress from './PropertyProfileInvestorProgress';
import { MultiSelect } from '@progress/kendo-react-dropdowns';
import { useParams } from 'react-router';
import PropertyConstruction from './PropertyConstruction';
import { Button } from '@progress/kendo-react-buttons';
import { Title } from '../../utils/title';
import { saveAs } from 'file-saver';
import { Link } from 'react-router-dom';
import { PropertyInvestorStatus } from 'TypeGen/property-investor-status';
import PropertyConstructionProjectSummary from './PropertyConstructionProjectSummary';
import { filterBy } from '@progress/kendo-data-query';
import { xIcon } from '@progress/kendo-svg-icons';

type Params = { propertyId: string; };

type PropertyNoteType = {
    ID: number;
    Note: string;
    DateTime: Date;
    User: { Name: string };
    IsUser: boolean;
}

type InvestorEngagement = {
    InvestorEngagementID: number;
    TypeName: string;
    DateTime: Date;
    Investor: { InvestorID: number, FullName: string };
}

export const PropertyProfile = () => {

    const { propertyId: propertyIdParam } = useParams<Params>()

    const popupAnchor = useRef<HTMLParagraphElement>(null);

    const [propertyId, setPropertyId] = useState<number>(Number(propertyIdParam));
    const [loading, setLoading] = useState(true);
    const [property, setProperty] = useState<PropertyViewModel | null>(null);
    const [propertyImages, setPropertyImages] = useState<PropertyImage[]>([]);
    const [propertyBids, setPropertyBids] = useState<PropertyBid[]>([]);
    const [propertyOffers, setPropertyOffers] = useState<PropertyOffer[]>([]);
    const [propertyInvestorFilter, setPropertyInvestorFilter] = useState<string>('');
    const [propertyInvestors, setPropertyInvestors] = useState<PropertyInvestor[]>([]);
    const [activeMarker, setActiveMarker] = useState<google.maps.LatLng | null>();
    const [showingInfoWindow, setShowingInfoWindow] = useState(false);
    const [showAgentAvatarListing, setShowAgentAvatarListing] = useState(false);
    const [showBidRejectDialog, setShowBidRejectDialog] = useState(false);
    const [bidRejectReason, setBidRejectReason] = useState('');
    const [propertyBidId, setPropertyBidId] = useState(0);
    const [showPropertyCloseDialog, setShowPropertyCloseDialog] = useState(false);
    const [propertyCloseDate, setPropertyCloseDate] = useState(Moment().local().toDate());
    const [showPropertySoldDialog, setShowPropertySoldDialog] = useState(false);
    const [showDownloadConstructionImagesDialog, setShowDownloadConstructionImagesDialog] = useState(false);
    const [constructionDownloadStartDate, setConstructionDownloadStartDate] = useState<Date | null>(null);
    const [constructionDownloadEndDate, setConstructionDownloadEndDate] = useState<Date | null>(Moment().local().endOf('day').toDate());
    const [showInvestorEngagementDialog, setShowInvestorEngagementDialog] = useState(false);
    const [investorEngagements, setInvestorEngagements] = useState<InvestorEngagement[]>([]);
    const [notes, setNotes] = useState<PropertyNoteType[]>([]);
    const [tags, setTags] = useState<string[]>([]);
    const [newNote, setNewNote] = useState('');
    const [noteDialog, setNoteDialog] = useState(false);
    const [auditDialog, setAuditDialog] = useState(false);
    const [viewingsDialog, setViewingsDialog] = useState(false);

    const toggleDialog = () => {
        if (!noteDialog) {
            fetchNotes();
        }
        setNoteDialog(!noteDialog);
    }

    const addNote = () => {

        if (!newNote) {
            alert("Please type a note!");
            return;
        }

        toggleDialog();

        fetchApi('/api/Property/PropertyNote', {
            PropertyID: propertyId,
            Note: newNote
        }, "POST")
            .then(() => {
                setNewNote('');
                fetch(propertyId);
            })
            .catch(() => {
                toggleDialog();
            });
    }

    const hasActiveBid = property && property.Status === PropertyStatus.Negotiated && propertyBids.length > 0;

    let images: imgDetail[] = [];

    if (property && propertyImages) {
        propertyImages.forEach(x => {
            images.push({
                src: `/api/Property/ViewPropertyImage/${x.PropertyImageID}?token=${localStorage.getItem('ACCESS_TOKEN')}`,
                title: getPropertyImageType(x.PropertyImageType),
                caption: getPropertyFrontBackProperty(property as PropertyViewModel, x.PropertyImageType)
            });
        });
    }

    const getStatusTitle = (status: PropertyStatus, property: PropertyViewModel) => {
        switch (status) {
            case PropertyStatus.Closed:
                return `Closed on ${Moment.utc(property.ClosedDateTime as Date).local().format("MM/DD/YYYY HH:mm")}`;
        }
        return "";
    }

    const updateProperty = (value: Partial<PropertyViewModel>) => {
        const newProperty = Object.assign({}, property, value);
        setProperty(newProperty);
    }

    const getPricePerLotSizeUnit = (bid: PropertyBid) => {
        if (property)
            return `(${formatCurrency(bid.Price / property.Specifications.SquareFeet)}/${getPropertyLotSizeUnit(property.Specifications.LotSizeUnit)})`;
        return '';
    }

    const toggleBidRejectDialog = (action: boolean) => {
        if (action) updateBid(propertyBidId, false);
        setShowBidRejectDialog(!showBidRejectDialog);
    }

    const togglePropertyCloseDialog = (action: boolean) => {
        if (action) closeProperty();
        setShowPropertyCloseDialog(!showPropertyCloseDialog);
    }

    const togglePropertySoldDialog = (action: boolean) => {
        if (action) soldProperty();
        setShowPropertySoldDialog(!showPropertySoldDialog);
    }

    const toggleDownloadConstructionImages = (action: boolean) => {
        if (action) downloadConstructionImages();
        setShowDownloadConstructionImagesDialog(!showDownloadConstructionImagesDialog);
    }

    const onMarkerClick = (marker: google.maps.Marker) => {
        setActiveMarker(marker.getPosition());
        setShowingInfoWindow(true);
    }

    const assignProperty = (agent: AgentViewModel) => {
        setLoading(true);

        const data = {
            PropertyID: property?.PropertyID,
            AgentID: agent.AgentID
        };

        fetchApi('/api/Property/Assign', data, 'POST').then((response: { Success: boolean, ErrorMessage: string }) => {
            if (response.Success) {
                setProperty({ ...property, AssignedAgent: agent } as PropertyViewModel);
                setLoading(false);
            } else {
                alert(response.ErrorMessage);
            }
        });
    }

    const unAssignProperty = () => {
        setLoading(true);

        const data = {
            PropertyID: property?.PropertyID,
        };

        fetchApi('/api/Property/UnAssign', data, 'POST').then((response: { Success: boolean, ErrorMessage: string }) => {
            if (response.Success) {
                setProperty({ ...property, AssignedAgent: null } as PropertyViewModel);
                setLoading(false);
            } else {
                alert(response.ErrorMessage);
            }
        });
    }

    const updateBid = (bidId: number, accepted: boolean) => {
        setLoading(true);

        const data = {
            PropertyID: propertyId,
            PropertyBidID: bidId,
            Accepted: accepted,
            RejectedReason: bidRejectReason
        };

        fetchApi(`/api/Property/UpdateBid`, data, 'POST')
            .then(() => {
                const status = data.Accepted ? PropertyStatus.BidAccepted : PropertyStatus.BidRejected;
                setProperty({ ...property, Status: status } as PropertyViewModel);
                setLoading(false);
                fetchBids();
            })
            .catch(() => {
                alert("System Error! Unable to update bid status!");
                setLoading(false);
            });
    }

    const activateProperty = (activate: boolean) => {
        setLoading(true);

        const data = {
            PropertyID: propertyId,
            Activate: activate
        };

        fetchApi(`/api/Property/ActivateProperty`, data, 'POST')
            .then(() => {
                setProperty({ ...property, Active: data.Activate } as PropertyViewModel);
                setLoading(false);
            });
    }

    const closeProperty = () => {
        setLoading(true);

        const data = {
            PropertyID: propertyId,
            CloseDate: Moment(Moment(propertyCloseDate).utc().format('MM/DD/YYYY')).toDate()
        };
        fetchApi(`/api/Property/CloseProperty`, data, 'POST')
            .then(() => {
                setProperty({ ...property, Status: PropertyStatus.Closed } as PropertyViewModel);
                setLoading(false);
            });
    }

    const undoPendingSale = () => {
        setLoading(true);
        const data = {
            PropertyID: propertyId
        };
        fetchApi(`/api/Property/UndoPropertySalePending`, data, 'POST')
            .then(() => {
                setProperty({ ...property, Status: PropertyStatus.Listed } as PropertyViewModel);
                setLoading(false);
            });
    }

    const soldProperty = () => {
        setLoading(true);

        const data = {
            PropertyID: propertyId,
            CloseDate: Moment(Moment(propertyCloseDate).utc().format('MM/DD/YYYY')).toDate()
        };
        fetchApi(`/api/Property/SoldProperty`, data, 'POST')
            .then(() => {
                setProperty({ ...property, Status: PropertyStatus.Sold } as PropertyViewModel);
                setLoading(false);
            });
    }

    const downloadConstructionImages = () => {
        setLoading(true);

        const data = {
            PropertyID: propertyId,
            StartDate: constructionDownloadStartDate,
            EndDate: constructionDownloadEndDate
        }

        fetchApi(`/api/Property/DownloadConstructionImages`, data, 'POST')
            .then((blob: { blob: Blob, filename: string }) => {
                var newBlob = new Blob([blob.blob], { type: 'application/pdf' });
                saveAs(newBlob, blob.filename);
                setLoading(false);
            })
            .catch(() => {
                alert("System Error! Unable to download property images!");
                setLoading(false);
            });
    }

    const fetch = useCallback((newPropertyId: number) => {
        setLoading(true);

        let a = new Promise((resolve, reject) => {
            fetchApi(`/api/Property/Get/${newPropertyId}`).then((data) => {
                resolve(data);
            }).catch(() => {
                reject(null);
            });
        });

        let b = new Promise((resolve, reject) => {
            fetchApi(`/api/Property/GetPropertyImages/${newPropertyId}`).then((data) => {
                resolve(data);
            }).catch(() => {
                reject(null);
            });
        });

        let c = new Promise((resolve, reject) => {
            fetchApi(`/api/Property/GetPropertyBids/${newPropertyId}`).then((data) => {
                resolve(data);
            }).catch(() => {
                reject(null);
            });
        });

        let d = new Promise((resolve, reject) => {
            fetchApi(`/api/Property/GetPropertyOffers/${newPropertyId}`).then((data) => {
                resolve(data);
            }).catch(() => {
                reject(null);
            });
        });

        let propertyInvestors = new Promise((resolve, reject) => {
            fetchApi(`/api/Property/GetPropertyInvestors/${newPropertyId}`).then((data) => {
                resolve(data);
            }).catch(() => {
                reject(null);
            });
        });

        Promise.all([a.catch(x => x), b.catch(x => x), c.catch(x => x), d.catch(x => x), propertyInvestors.catch(x => x)])
            .then((data) => {
                setProperty(data[0] ? data[0] as PropertyViewModel : null);
                setPropertyImages(data[1] ? data[1] as PropertyImage[] : []);
                setPropertyBids(data[2] ? data[2] as PropertyBid[] : []);
                setPropertyOffers(data[3] ? _sortPropertyOffers(data[3] as PropertyOffer[]) : []);
                setPropertyInvestors(data[4] ? _sortPropertyInvestors(data[4] as PropertyInvestor[]) : [])
                setShowAgentAvatarListing(true);
                setLoading(false);
            });
    }, [])

    const fetchTags = () => {
        fetchApi('/api/Property/PropertyTags')
            .then((response: { Tags: string[] }) => {
                setTags(response.Tags);
            })
    }

    const fetchInvestorEngagement = useCallback((propertyId: number) => {
        fetchApi(`/api/Property/InvestorEngagement/${propertyId}`)
            .then((response: { Engagements: InvestorEngagement[] }) => {
                setInvestorEngagements(response.Engagements);
            });
    }, []);


    const _sortPropertyOffers = (propertyOffers: PropertyOffer[]): PropertyOffer[] => {

        let sortedList: PropertyOffer[] = [];
        sortedList = sortedList.concat(propertyOffers.filter(x => x.OfferStatus === PropertyOfferStatus.Accepted));
        sortedList = sortedList.concat(propertyOffers.filter(x => x.OfferStatus === PropertyOfferStatus.Countered));
        sortedList = sortedList.concat(propertyOffers.filter(x => x.OfferStatus === PropertyOfferStatus.Submitted));
        sortedList = sortedList.concat(propertyOffers.filter(x => x.OfferStatus !== PropertyOfferStatus.Accepted && x.OfferStatus !== PropertyOfferStatus.Countered &&
            x.OfferStatus !== PropertyOfferStatus.Submitted));

        return sortedList;
    };

    const _sortPropertyInvestors = (propertyInvestors: PropertyInvestor[]): PropertyInvestor[] => {

        let sortedList: PropertyInvestor[] = [];
        sortedList = sortedList.concat(propertyInvestors.filter(x => x.Status === PropertyInvestorStatus.Submitted));
        sortedList = sortedList.concat(propertyInvestors.filter(x => x.Status === PropertyInvestorStatus.Accepted));
        sortedList = sortedList.concat(propertyInvestors.filter(x => x.Status === PropertyInvestorStatus.Rejected));
        sortedList = sortedList.concat(propertyInvestors.filter(x => x.Status === PropertyInvestorStatus.Exitted));
        return sortedList;
    };

    const fetchProperty = () => {
        setLoading(true);

        fetchApi(`/api/Property/Get/${propertyId}`)
            .then((data: PropertyViewModel) => {
                setProperty(data);
                setLoading(false);
            });
    }

    const fetchBids = () => {
        fetchApi(`/api/Property/GetPropertyBids/${propertyId}`)
            .then((data: PropertyBid[]) => {
                setPropertyBids(data);
            }).catch((e) => {
                console.error(e);
            });
    }

    const fetchImages = () => {
        fetchApi(`/api/Property/GetPropertyImages/${propertyId}`)
            .then((propertyImages: PropertyImage[]) => {
                setPropertyImages(propertyImages);
            }).catch((e) => {
                console.error(e);
            });
    }

    const fetchNotes = () => {
        fetchApi(`/api/Property/PropertyNotes/${propertyId}`)
            .then((response: { Notes: PropertyNoteType[] }) => {
                setNotes(response.Notes);
            }).catch((e) => {
                console.error(e);
            });
    }

    const fetchOffers = () => {
        fetchApi(`/api/Property/GetPropertyOffers/${propertyId}`)
            .then((data: PropertyOffer[]) => {
                setPropertyOffers(_sortPropertyOffers(data));
            }).catch((e) => {
                console.error(e);
            });
    }

    const fetchInvestors = () => {
        fetchApi(`/api/Property/GetPropertyInvestors/${propertyId}`)
            .then((data: PropertyInvestor[]) => {
                setPropertyInvestors(_sortPropertyInvestors(data));
            }).catch((e) => {
                console.error(e);
            });
    }

    useEffect(() => {
        fetch(propertyId);
    }, [fetch, propertyId]);

    useEffect(() => {
        fetchTags();
    }, []);

    useEffect(() => {
        setPropertyId(Number(propertyIdParam));
    }, [propertyIdParam]);

    useEffect(() => {
        if (showInvestorEngagementDialog) {
            fetchInvestorEngagement(propertyId);
        }
    }, [fetchInvestorEngagement, propertyId, showInvestorEngagementDialog]);

    return (<>
        {loading && <LoadingPanel />}
        {auditDialog && <AuditLogs PropertyID={propertyId} CloseDialog={() => setAuditDialog(false)} />}
        {viewingsDialog && <PropertyProfileViewings PropertyID={propertyId} CloseDialog={() => setViewingsDialog(false)} />}
        {noteDialog &&
            <Window
                title={"Property Notes"}
                onClose={toggleDialog}
                style={{ position: 'fixed', maxWidth: 850, width: '100%' }}
                initialHeight={550}
                initialLeft={(window.innerWidth / 2) - 425}
            >
                <form className="k-form k-form-md">
                    <fieldset>
                        <label className="k-form-field">
                            <TextArea
                                placeholder="Type new note..."
                                value={newNote}
                                onChange={(e) => setNewNote(e.value)}
                            />
                        </label>
                    </fieldset>
                    <div className="text-end">
                        <Button themeColor="primary" onClick={addNote}>Add Note</Button>
                    </div>
                    <br />

                    <div className="k-chat" style={{ maxWidth: 'initial', height: 'initial', borderWidth: 0 }}>
                        {notes.map((note) => <div key={note.ID} className={`k-message-group${note.IsUser ? ' k-alt' : ''}`}>
                            <p className="k-author">{note.User.Name}</p>
                            <div className="k-first k-state-selected k-message">
                                <time className="k-message-time" aria-hidden="true">{Moment.utc(note.DateTime).local().format("MM/DD/YYYY HH:mm")}</time>
                                <Linkify
                                    componentDecorator={(decoratedHref: string, decoratedText: string, key: number) =>
                                        <a key={key} style={{ color: 'inherit' }} target="_blank" rel="noreferrer" href={decoratedHref}>{decoratedText}</a>
                                    }
                                >
                                    <div className="k-bubble"><p>{note.Note}</p></div>
                                </Linkify>
                            </div>
                        </div>)}
                    </div>
                </form>
            </Window>}
        {property &&
            <div className="container-fluid">
                <Title string={property.Address.StreetAddress} />
                <div className="row">
                    <div className="col">
                        &nbsp;
                    </div >
                </div >
                <div className="row h-100">
                    <div className="col-md-7" style={{ position: "relative" }}>
                        <div className="row">
                            <div className="col-md-12">
                                <h2>
                                    {property.Address.StreetAddress}
                                </h2>
                                <h3>
                                    {`${property.Address.City}, ${property.Address.State}  ${property.Address.Zip}`} <br />
                                </h3>
                                <h6 className="mt-2" style={{ display: 'flex' }}>
                                    <div style={{ fontStyle: "italic" }} title={getStatusTitle(property.Status, property)}>{getPropertyStatus(property.Status).toUpperCase()} {property.Active ? '' : ' / DISCARDED'}</div>
                                    {property.Status >= PropertyStatus.Listed &&
                                        <a href="/#"
                                            title="Viewings"
                                            style={{ marginLeft: "5px", marginTop: "-2px", textDecoration: 'none' }}
                                            onClick={(e) => {
                                                setViewingsDialog(true);
                                                e.preventDefault();
                                            }}><span className="k-icon k-i-calendar" style={{ fontSize: "20px" }}></span>
                                        </a>}
                                    {property.Active ?
                                        <a href="/#"
                                            title="Discard/Hide Property?"
                                            style={{ marginLeft: "5px", marginTop: "-2px", textDecoration: 'none' }}
                                            onClick={(e) => {
                                                if (window.confirm("Discard/Hide Property?")) {
                                                    activateProperty(false);
                                                }
                                                e.preventDefault();
                                            }}>
                                            <span className="k-icon k-i-delete" style={{ fontSize: "20px" }} />
                                        </a>
                                        :
                                        <a href="/#"
                                            title="Property was discarded.  Restore?"
                                            style={{ marginLeft: "5px", marginTop: "-2px", textDecoration: 'none' }}
                                            onClick={(e) => {
                                                if (window.confirm("Restore Property?")) {
                                                    activateProperty(true);
                                                }
                                                e.preventDefault();
                                            }}>
                                            <span className="k-icon k-i-reset" style={{ fontSize: "20px" }} />
                                        </a>
                                    }
                                    {property.Status === PropertyStatus.BidAccepted &&
                                        <a href="/#"
                                            title="Mark Purchased?"
                                            style={{ marginLeft: "5px", marginTop: "-2px", textDecoration: 'none' }}
                                            onClick={(e) => {
                                                setShowPropertyCloseDialog(true);
                                                e.preventDefault();
                                            }}><span className="k-icon k-i-dollar" style={{ fontSize: "20px" }}></span>
                                        </a>}
                                    <a href="/#"
                                        title="Property Images"
                                        style={{ marginLeft: "5px", marginTop: "-2px", textDecoration: 'none' }}
                                        onClick={(e) => {
                                            setShowDownloadConstructionImagesDialog(true);
                                            e.preventDefault();
                                        }}><span className="k-icon k-i-image" style={{ fontSize: "20px" }}></span>
                                    </a>
                                    {property.InvestmentSpecifications.length > 0 && <a href="/#"
                                        title="Investor Engagement"
                                        style={{ marginLeft: "5px", marginTop: "-2px", textDecoration: 'none' }}
                                        onClick={(e) => {
                                            setShowInvestorEngagementDialog(true);
                                            e.preventDefault();
                                        }}><span className="k-icon k-i-preview" style={{ fontSize: "20px" }}></span>
                                    </a>}
                                    {(property.Status === PropertyStatus.Listed || property.Status === PropertyStatus.SalePending) && <>
                                        <a href="/#"
                                            title="Sold?"
                                            style={{ marginLeft: "5px", marginTop: "-2px", textDecoration: 'none' }}
                                            onClick={(e) => {
                                                setShowPropertySoldDialog(true);
                                                e.preventDefault();
                                            }}><span className="k-icon k-i-dollar" style={{ fontSize: "20px" }}></span>
                                        </a>
                                        <a href="/#"
                                            title="Undo Sale Pending?"
                                            style={{ marginLeft: "5px", marginTop: "-2px", textDecoration: 'none' }}
                                            onClick={(e) => {
                                                if (window.confirm("Return Property to Listed status?")) {
                                                    undoPendingSale();
                                                }
                                                e.preventDefault();
                                            }}><span className="k-icon k-i-undo" style={{ fontSize: "20px" }}></span>
                                        </a>
                                    </>}
                                    <a
                                        href="/#"
                                        title="Audit Logs"
                                        style={{ marginLeft: "auto", marginTop: "-2px", textDecoration: 'none' }}
                                        onClick={(e) => {
                                            setAuditDialog(true);
                                            e.preventDefault();
                                        }}><span className="k-icon k-i-track-changes" style={{ fontSize: "20px" }}></span>
                                    </a>
                                </h6>
                            </div>
                        </div>
                        {property.Status >= PropertyStatus.Reserved && <div className="row mt-3 px-2">
                            <PropertyProfileAttributes
                                property={property}
                                images={propertyImages}
                                refresh={() => {
                                    fetchProperty();
                                    fetchImages();
                                }}
                            />
                        </div>}
                        {property.Status >= PropertyStatus.Qualified && <div className="row mt-3 px-2">
                            <PropertyProfileSpecifications property={property} refresh={fetchProperty} />
                        </div>}
                        {property.Status >= PropertyStatus.Registered && <div className="row mt-3 px-2">
                            <PropertyProfileCommunication property={property} refresh={fetchProperty} />
                        </div>}
                        {property.InvestmentSpecifications.map(investmentSpecification => {
                            return <div key={investmentSpecification.PropertyInvestorOfferingID} className="row mt-3 px-2">
                                <PropertyProfileInvestment
                                    property={property}
                                    InvestmentSpecification={investmentSpecification}
                                    images={propertyImages.filter(x => x.PropertyImageType === PropertyImageType.InvestorListing)}
                                    refresh={() => {
                                        fetchProperty();
                                        fetchImages();
                                    }}
                                />
                            </div>
                        })}
                        {property.Status >= PropertyStatus.Closed && <div className="row mt-3 px-2">
                            <PropertyProfileListing property={property} refresh={fetchProperty} />
                        </div>}
                        <div className="row mt-3">
                            <div className="col-md px-2 mb-2" style={{ minWidth: "250px" }}>
                                <AgentAvatar
                                    title="Submitted By"
                                    titleClassName="property-panel-title"
                                    agent={property.SubmittedAgent}
                                />
                            </div>

                            {
                                property.AssignedAgent ?
                                    <div className="col-md px-2 mb-2" style={{ minWidth: "250px" }}>
                                        <AgentAvatar
                                            title="Assigned To"
                                            titleClassName="property-panel-title"
                                            onIconClicked={() => unAssignProperty()}
                                            agent={property.AssignedAgent}
                                        />
                                    </div> :
                                    <div className="col-md px-2 mb-2" style={{ minWidth: "250px" }}>
                                        <p className="property-panel-title" ref={popupAnchor}>Assign To</p>
                                        <div style={{ position: "relative" }}>
                                            {showAgentAvatarListing && <AgentAvatarListing
                                                anchor={popupAnchor.current as HTMLElement}
                                                close={(agent: AgentViewModel | undefined) => {
                                                    if (agent) assignProperty(agent);
                                                }}
                                                showSearchInput={true}
                                                agentLevelExclusionList={property.Status === PropertyStatus.Initiated
                                                    ? [AgentLevel.ScoutBasic, AgentLevel.Contractor, AgentLevel.ScoutPremium]
                                                    : [AgentLevel.ScoutBasic]}
                                            />}
                                        </div>
                                    </div>
                            }

                            <div className="col-md px-2 mb-2" style={{ minWidth: "250px" }}>
                                <div className='groupbox' style={{ minHeight: 125, height: '100%' }}>
                                    <div style={{ padding: '.25em' }}>
                                        <p className="property-panel-title" style={{ marginBottom: 8 }}>Latest Note</p>
                                        <p style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', marginBottom: 6 }}>{property.LatestNote || "No Notes"}</p>
                                        <Button fillMode="link" themeColor="primary" style={{ width: '100%', margin: '0 auto' }} onClick={toggleDialog}>View Notes</Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row mt-3">
                            <div className="col-md px-2">
                                <MultiSelect
                                    data={tags}
                                    label="Label Property"
                                    style={{ width: '100%' }}
                                    onChange={(e) => {
                                        const newTags = e.target.value.length === 0 ? null : e.target.value.join('|');
                                        const data = {
                                            PropertyID: property?.PropertyID,
                                            Tags: newTags
                                        };
                                        fetchApi('/api/Property/PropertyTags', data, 'POST')
                                            .then(() => {
                                                updateProperty({ Tags: newTags });
                                            })
                                            .catch(() => {
                                                alert('Unable to update tags');
                                            });
                                    }}
                                    value={property.Tags?.split('|')}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-5 mt-3">
                        <div style={{ position: "relative", minHeight: "500px" }}>
                            <Map
                                clickableIcons={true}
                                center={{
                                    lat: property.Address.Latitude,
                                    lng: property.Address.Longitude,
                                }}
                                zoom={11}
                                streetViewControl={true}
                                scrollwheel={false}
                                style={{ position: "absolute", height: "100%", width: "100%" }}
                            >
                                <Marker
                                    onClick={(marker) => onMarkerClick(marker)}
                                    position={{
                                        lat: property.Address.Latitude,
                                        lng: property.Address.Longitude,
                                    }}
                                />
                                {showingInfoWindow && <InfoWindow
                                    position={activeMarker}
                                    pixelOffset={new window.google.maps.Size(0, -37)}
                                    content={`<div>
                                        <p class="text-dark m-0">
                                            ${property && `${property.Address.StreetNumber} ${property.Address.StreetName}`}
                                        </p>
                                        <p class="text-dark m-0">
                                            ${property && `${property.Address.City}, ${property.Address.State}  ${property.Address.Zip}`}
                                        </p>
                                        <p class="text-dark m-0" style="fontStyle: "italic">
                                            <a href="https://www.google.com/maps/place/${property.Address.StreetNumber} ${property.Address.StreetName} ${property.Address.City}, ${property.Address.State} ${property.Address.Zip}'" target="_blank" style="color:#007bff">View on Google Maps</a>
                                        </p>
                                    </div>`}
                                />}
                            </Map>
                        </div>
                    </div>
                </div>

                {property.InvestmentSpecifications.filter(x => x.Status !== PropertyInvestorOfferingStatus.Draft).map(investmentSpecification => {
                    const investors = propertyInvestors.filter(x => x.PropertyInvestorOfferingID === investmentSpecification.PropertyInvestorOfferingID);
                    const filteredInvestors = filterBy(investors, { logic: 'or', filters: [
                        { field: 'SubmittedInvestor.FullName', operator: 'contains', value: propertyInvestorFilter },
                        { field: 'SubmittedInvestor.Email', operator: 'contains', value: propertyInvestorFilter }
                    ] });
                    return <div key={investmentSpecification.PropertyInvestorOfferingID} className="mt-3">
                        <div className="row px-2 mb-1">
                            <FadeInDivPanel ignoreTransitionShowAll={investors.length < 3 || investmentSpecification.Status === PropertyInvestorOfferingStatus.Open}>
                                <PropertyProfileInvestorProgress InvestmentSpecification={investmentSpecification} propertyInvestors={investors} refresh={() => fetchProperty()} />
                                {investors.length > 0 && <div style={{ maxHeight: 800, minHeight: 260, overflowY: 'auto', overflowX: 'hidden' }}>
                                    <TextBox
                                        placeholder="Filter by investor name or email"
                                        className='my-2 w-50'
                                        value={propertyInvestorFilter}
                                        onChange={(e) => setPropertyInvestorFilter(e.value as string)}
                                        suffix={() => <InputSuffix>
                                            <Button
                                                icon="close"
                                                svgIcon={xIcon}
                                                disabled={!propertyInvestorFilter}
                                                onClick={() => setPropertyInvestorFilter('')}
                                            />
                                        </InputSuffix>}
                                    />
                                    {filteredInvestors.map((item, index) =>
                                        <PropertyProfileInvestor
                                            key={item.PropertyInvestorID}
                                            item={item}
                                            firstItem={index === 0}
                                            propertyID={propertyId}
                                            refresh={() => {
                                                fetchProperty();
                                                fetchInvestors();
                                            }} />)}
                                </div>}
                            </FadeInDivPanel>
                        </div>
                    </div>
                })}

                {property.InvestmentSpecifications.find(x => x.Status === PropertyInvestorOfferingStatus.Finalized) && <>
                    <div className="mt-3">
                        <div className="row px-2">
                            <FadeInDivPanel>
                                <PropertyConstruction
                                    property={property}
                                />
                            </FadeInDivPanel>
                        </div>
                        <hr />
                    </div>
                </>}

                <div className="mt-3">
                    <div className="row px-2">
                        <FadeInDivPanel ignoreTransitionShowAll>
                            <PropertyConstructionProjectSummary
                                constructionProject={property.ConstructionProject}
                                propertyId={property.PropertyID}
                            />
                        </FadeInDivPanel>
                    </div>
                </div>



                {[PropertyStatus.Listed].indexOf(property.Status) !== -1 && property.HasAddBidAuthorization && <>
                    <PropertyProfileAddOffer property={property} refresh={() => {
                        fetchProperty();
                        fetchOffers();
                    }} />
                </>}
                {propertyOffers.length > 0 && <div className="mt-3">
                    <div className="row px-2">
                        <FadeInDivPanel ignoreTransitionShowAll={propertyOffers.length < 3 || property.Status < PropertyStatus.Listed}>
                            <p className="property-panel-title">Offers</p>
                            {propertyOffers.map((item, index) =>
                                <PropertyProfileOffer key={index} item={item} index={index} propertyID={propertyId}
                                    refresh={() => {
                                        fetchProperty();
                                        fetchOffers();
                                    }} />)}
                        </FadeInDivPanel>
                    </div>
                    <hr />
                </div>}

                {[PropertyStatus.Registered, PropertyStatus.Initiated, PropertyStatus.Negotiated, PropertyStatus.BidRejected, PropertyStatus.BidAccepted].indexOf(property.Status) !== -1 && property.HasAddBidAuthorization && <>
                    <PropertyProfileAddBid property={property} refresh={() => {
                        fetchProperty();
                        fetchBids();
                    }} />
                </>}
                {propertyBids.length > 0 && <div className="mt-3">
                    <div className="row px-2">
                        <FadeInDivPanel ignoreTransitionShowAll={propertyBids.length < 3 || property.Status < PropertyStatus.BidAccepted}>
                            <p className="property-panel-title">Bids</p>

                            {propertyBids.map((bid, index) => {
                                const isActiveBid = hasActiveBid && index === 0;
                                const isBidAccepted = bid.BidStatus === PropertyBidStatus.Accepted || (index === 0 && (property &&
                                    property.Status >= PropertyStatus.BidAccepted && property.Status !== PropertyStatus.Cancelled));
                                return (
                                    <React.Fragment key={index}>
                                        {index > 0 && <hr />}
                                        <div className="row">
                                            <div className="col-lg-6">
                                                <div style={{ fontSize: "13px" }}><label className="fw-bold">Bid Date:</label>&nbsp;
                                                    {Moment.utc(bid.SubmittedDateTime).local().format("MM/DD/YYYY HH:mm")}
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-lg-2">
                                                <div className="fw-bold">Bid Price</div>
                                                <div>{formatCurrency(bid.Price)} {getPricePerLotSizeUnit(bid)}
                                                    <br />
                                                    <span style={{ fontSize: "12px" }}>
                                                        ARV: {formatCurrency(bid.AfterRepairValue)} [{getPropertyRenovation(bid.PropertyRenovation)} Construction]
                                                    </span>
                                                    <br />
                                                    <span style={{ fontSize: "12px" }}>
                                                        Rehab Cost: {formatCurrency(bid.RehabCost)}
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="col-lg-2">
                                                <div className="fw-bold">Earnest Deposit [Days]</div>
                                                <div>{formatCurrency(bid.EarnestMoneyDeposit)} [{bid.EarnestMoneyDepositDays}]</div>
                                            </div>
                                            {isActiveBid ?
                                                <div className="col-lg-2">
                                                    <button type="button" className="btn btn-primary" style={{ marginRight: "2px" }} disabled={!property?.HasBidAuthorization}
                                                        onClick={() => updateBid(bid.PropertyBidID, true)}
                                                    >ACCEPT</button>
                                                    <button type="button" className="btn btn-warning" disabled={!property?.HasBidAuthorization}
                                                        onClick={() => {
                                                            setPropertyBidId(bid.PropertyBidID);
                                                            setShowBidRejectDialog(true);
                                                        }}
                                                    >REJECT</button>
                                                </div>
                                                :
                                                <div className="col-lg-2">
                                                    {isBidAccepted ?
                                                        <div><h4><span style={{ padding: "8px" }} className="badge bg-primary fw-normal">ACCEPTED</span></h4></div> :
                                                        <div><h4><span style={{ padding: "8px" }} className="badge bg-warning text-black fw-normal">REJECTED</span></h4></div>}
                                                </div>
                                            }
                                        </div>

                                        <div className="row" style={{ marginTop: "2px" }}>
                                            <div className="col-lg-2">
                                                <div className="fw-bold">Escrow Fees Paid By [Days]</div>
                                                <div>{getPropertyEscrow(bid.Escrow)} [{bid.CloseOfEscrowDays}]</div>
                                            </div>
                                            <div className="col-lg-2">
                                                <div className="fw-bold">Title Fees Paid By</div>
                                                <div>{getPropertyTitle(bid.Title)}</div>
                                            </div>
                                            <div className="col-lg-2">
                                                <div className="fw-bold">HOA Fees Paid By</div>
                                                <div>{getPropertyHOA(bid.HOA)}</div>
                                            </div>
                                            <div className="col-lg-2">
                                                <div className="fw-bold">Escalation Clause</div>
                                                <div>{toYesNo(bid.EscalactionClause)}{(bid.EscalactionClause === true && bid.EscalactionClauseReason) ? `: ${bid.EscalactionClauseReason}` : ""}</div>
                                            </div>
                                            <div className="col-lg-2">
                                                <div className="fw-bold">Notes</div>
                                                <div>{bid.Notes}</div>
                                            </div>
                                            {
                                                bid.ReasonForRejecting &&
                                                <div className="col-lg-2">
                                                    <div className="fw-bold">Bid Rejection Reason</div>
                                                    <div>{bid.ReasonForRejecting}</div>
                                                </div>
                                            }
                                        </div>
                                    </React.Fragment>
                                );
                            })}
                        </FadeInDivPanel>
                    </div>
                    <hr />
                </div>}

                <div className="row h-100 mb-3 mt-3">
                    <div className="col-md-12" style={{ position: "relative" }}>
                        {images && <ImageViewer images={images} CloseDialog={() => { }} />}
                    </div>
                </div>
            </div>
        }

        {showBidRejectDialog && <Dialog title={"Reject Bid w/Reason"} onClose={() => toggleBidRejectDialog(false)}>
            <p style={{ margin: "25px", textAlign: "center" }}><TextArea placeholder="Enter reason..." value={bidRejectReason} onChange={(e: any) => setBidRejectReason(e.value)} /></p>
            <DialogActionsBar>
                <Button onClick={() => toggleBidRejectDialog(false)}>CANCEL</Button>
                <Button themeColor="primary" onClick={() => toggleBidRejectDialog(true)}>REJECT BID</Button>
            </DialogActionsBar>
        </Dialog>
        }

        {showPropertyCloseDialog && <Dialog title="Close Property" onClose={() => togglePropertyCloseDialog(false)}>
            <p style={{ margin: "25px", textAlign: "center" }}>
                <div className="form-group">
                    <label className="me-2">
                        Close Date
                    </label>
                    <DatePicker
                        required
                        format="MM/dd/yyyy"
                        defaultValue={undefined}
                        value={propertyCloseDate}
                        onChange={(e) => setPropertyCloseDate(e.value || propertyCloseDate)}
                    />
                </div>
            </p>
            <DialogActionsBar>
                <Button onClick={() => togglePropertyCloseDialog(false)}>CANCEL</Button>
                <Button themeColor="primary" onClick={() => togglePropertyCloseDialog(true)}>SAVE</Button>
            </DialogActionsBar>
        </Dialog>}

        {showPropertySoldDialog && <Dialog title="Sold Property" onClose={() => togglePropertySoldDialog(false)}>
            <p style={{ margin: "25px", textAlign: "center" }}>
                <div className="form-group">
                    <label className="me-2">
                        Close Date
                    </label>
                    <DatePicker
                        required
                        format="MM/dd/yyyy"
                        defaultValue={undefined}
                        value={propertyCloseDate}
                        onChange={(e) => setPropertyCloseDate(e.value || propertyCloseDate)}
                    />
                </div>
            </p>
            <DialogActionsBar>
                <Button onClick={() => togglePropertySoldDialog(false)}>CANCEL</Button>
                <Button themeColor="primary" onClick={() => togglePropertySoldDialog(true)}>MARK SOLD</Button>
            </DialogActionsBar>
        </Dialog>}

        {showDownloadConstructionImagesDialog && <Dialog title="Download Construction Images" onClose={() => toggleDownloadConstructionImages(false)}>
            <p style={{ margin: "25px" }}>
                <div className="form-group">
                    <label className="me-2">
                        Start Date
                    </label>
                    <DatePicker
                        format="MM/dd/yyyy"
                        value={constructionDownloadStartDate}
                        onChange={(e) => setConstructionDownloadStartDate(e.value)}
                    />

                    <label className="me-2">
                        End Date
                    </label>
                    <DatePicker
                        format="MM/dd/yyyy"
                        value={constructionDownloadEndDate}
                        onChange={(e) => setConstructionDownloadEndDate(e.value)}
                    />
                </div>
            </p>
            <DialogActionsBar>
                <Button onClick={() => toggleDownloadConstructionImages(false)}>CANCEL</Button>
                <Button themeColor="primary" onClick={() => toggleDownloadConstructionImages(true)}>DOWNLOAD PDF</Button>
            </DialogActionsBar>
        </Dialog>}

        {showInvestorEngagementDialog && <Window
            title="Investor Engagement"
            onClose={() => setShowInvestorEngagementDialog(false)}
            initialWidth={Math.min(window.innerWidth, 600)}
            initialHeight={650}
        >
            {investorEngagements.length === 0 && <div style={{ textAlign: "center", padding: "25px" }}>No investor engagements found.</div>}
            {investorEngagements.map((engagement) => {
                return (
                    <div key={engagement.InvestorEngagementID} className="row">
                        <p className="text-center">
                            <Link to={`/Investor/${engagement.Investor.InvestorID}/Profile`}>{engagement.Investor.FullName}</Link> {engagement.TypeName} on {Moment.utc(engagement.DateTime).local().format("MM/DD/YYYY HH:mm")}
                        </p>
                    </div>);
            })}

        </Window>}
    </>);
}

export default PropertyProfile;